<template>
    <div v-if="campaignIconUrl" class="campaign-image">
        <img v-on-error :src="campaignIconUrl" alt="campaign-icon">
    </div>
    <CIcon v-else-if="campaignCode" :name="campaignCode" v-bind="$attrs"/>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator';
import Api from '@/project/http/Api.service';

const CLEARANCE_CODE = 'clearance';

@Component
export default class CampaignIcon extends Vue {
    @Prop({ required: true, type: String })
    campaignCode!: string;

    campaignIconUrl: string | null = null;

    async getCampaignWithIcon() {
        // This icon is a local SVG
        if (!this.campaignCode || this.campaignCode.toLowerCase() === CLEARANCE_CODE) return;

        try {
            const campaign = await Api.product.getCampaignWithIcon(this.campaignCode);
            this.campaignIconUrl = campaign.campaignIconUrl ?? null;
        } catch {
            // not found
        }
    }

    created() {
        this.getCampaignWithIcon();
    }
}
</script>

<style lang="less" scoped>
.campaign-image {
    height: 25px;
    width: 25px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
        filter: brightness(0) invert(1);
    }
}
</style>
