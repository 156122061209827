<template>
    <div v-if="isImpersonating" class="impersonation z-header fixed top-0 left-0 right-0 bg-red-300">
        <div class="impersonation bg-red-300 text-white flex items-center o-chrome-container">
            <span>{{ $dictionary.get('MyAccount.Impersonate.CurrentlyImpersonating') }}</span>
            <span class="ml-5 font-semi-bold">{{ impersonateeName }}</span>
            <call-to-action class="ml-auto" :text="$dictionary.get('MyAccount.Impersonate.EndImpersonateBtn')"
                            @click="endImpersonation()"/>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import impersonationStore from '@/project/shared/impersonation/impersonation.service';

@Component
export default class ImpersonationBanner extends Vue {
    get isImpersonating(): boolean {
        return impersonationStore.isImpersonating;
    }

    get impersonateeName(): string {
        return impersonationStore.impersonateeName;
    }

    endImpersonation(): void {
        impersonationStore.endImpersonation();
    }
}
</script>

<style lang="less" scoped>
.impersonation {
    height: 6rem;
}
</style>
