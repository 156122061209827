<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import loginFormOverlayService from '@/project/modals/overlays/LoginFormOverlay.service';
import authService from '@/core/auth/auth.service';
import serverContextService from '@/core/serverContext.service';
import { UrlQueryKey } from '@/core/urlQueryKey';

@Component
export default class AuthenticationModule extends Vue {
    checkForPasswordReset() {
        const params = new URLSearchParams(location.search);
        if (params.has(UrlQueryKey.ResetPassword)) {
            loginFormOverlayService.show('reset-pw', undefined, {
                singleton: true,
                closeOnBreakpointChange: false,
                closeOnRouteChange: false,
                showCloseButton: false,
                disableUserClose: true
            });
        }
    }

    checkForPasswordForgot() {
        const params = new URLSearchParams(location.search);
        if (params.has(UrlQueryKey.ForgotPassword)) {
            loginFormOverlayService.show('forgot-pw', undefined, {
                singleton: true,
                closeOnBreakpointChange: false,
                closeOnRouteChange: false,
                showCloseButton: true,
                disableUserClose: false
            });
        }
    }

    checkForLoginDialog() {
        const params = new URLSearchParams(location.search);
        if (params.has(UrlQueryKey.ShowLoginDialog)) {
            loginFormOverlayService.show('login', undefined, {
                singleton: true,
                closeOnBreakpointChange: false,
                closeOnRouteChange: true,
                showCloseButton: true,
                disableUserClose: false
            });
        }
    }

    checkForOciSession() {
        const params = new URLSearchParams(location.search);
        if (params.has(UrlQueryKey.PunchOutSession) && serverContextService.punchOutJwtToken) {
            authService.setToken(serverContextService.punchOutJwtToken);
        }
    }

    render() {
        return undefined;
    }

    created() {
        this.checkForPasswordReset();
        this.checkForPasswordForgot();
        this.checkForOciSession();
        setTimeout(() => {
            // Needs timeout to let the page load
            this.checkForLoginDialog();
        }, 200);
    }
}
</script>
