<template>
    <footer v-if="shouldRender">
        <section class="bg-white text-black">
            <div class="o-chrome-container">
                <div class="pt-30 md:pt-40 pb-20 md:pb-30 o-grid">
                    <section v-for="(column, ix) in firstLinkColumns" :key="ix" class="o-grid--item mb-20 lg:mb-0 w-1/2 md:w-1/4 lg:w-2/12">
                        <header class="text-13 md:text-15 font-bold lg:text-18 mb-10">
                            {{ column.title }}
                        </header>

                        <CultureAwareRouterLink v-for="link in column.links" :key="link.url"
                                                :to="link.url"
                                                class="block text-12 md:text-13 lg:text-15 py-5 hover:text-gray-800">
                            <template v-if="link.name === 'Brands'">
                                {{ $dictionary.get('Breadcrumbs.Brands') }}
                            </template>
                            <template v-else-if="link.name === 'Departments'">
                                {{ $dictionary.get('Breadcrumbs.Departments') }}
                            </template>
                            <template v-else>
                                {{ link.name }}
                            </template>
                        </CultureAwareRouterLink>

                        <a v-for="soMeLink in column.socialMediaLinks"
                           :key="soMeLink.url"
                           :href="soMeLink.url"
                           :target="soMeLink.target"
                           class="social-media-link flex flex-row items-center text-12 md:text-13 lg:text-15 py-5 hover:text-gray-800">
                            <img v-if="soMeLink.icon"
                                 :src="soMeLink.icon"
                                 :alt="soMeLink.name"
                                 loading="lazy"
                                 class="mr-8">
                            <span>
                                {{ soMeLink.name }}
                            </span>
                        </a>
                    </section>
                    <div v-if="salesRep || companyDetails" class="o-grid--item w-full md:w-1/2 lg:w-3/12 lg:ml-auto">
                        <header v-if="!salesRep" class="text-13 md:text-15 font-bold lg:text-18 mb-10">
                            {{ $dictionary.get('Footer.ContactTitle') }}
                        </header>
                        <EmployeeBlockItem
                            v-if="salesRepAsEmployeeViewObject"
                            :content="salesRepAsEmployeeViewObject"
                            :show-image-placeholder-if-empty="false"
                            :name-line-class="'text-13 md:text-15 font-bold lg:text-18 mb-3'"
                            :title-line-class="'mb-22 text-12 md:text-13 lg:text-15'"
                            :text-line-class="'my-10 text-12 md:text-13 lg:text-15'"
                            class="mb-10 w-full"/>
                        <template v-if="companyDetails">
                            <div>
                                <div class="mb-10 font-semi-bold text-12 md:text-13 lg:text-15">
                                    {{ companyDetails.companyName }}
                                </div>
                                <div v-if="companyDetails.phoneNumber" class="text-12 md:text-13  lg:text-15 py-5">
                                    <a :href="`tel:${companyDetails.phoneNumber}`">
                                        {{ companyDetails.phoneNumber }}
                                    </a>
                                </div>
                                <div v-if="companyDetails.email" class="text-12 md:text-13 lg:text-15 py-5">
                                    <a :href="'mailto:' + companyDetails.email" target="_blank" class="underline">
                                        {{ companyDetails.email }}
                                    </a>
                                </div>
                            </div>
                            <div class="mt-20 mb-10 font-semi-bold text-12 md:text-13 lg:text-15">
                                {{ $dictionary.get('Footer.OpeningHoursTitle') }}:
                            </div>
                            <p class="text-12 md:text-13 lg:text-15">
                                {{ companyDetails.openingHours }}
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </section>
        <section class="address-n-business-unit h-150 md:h-80 py-20 bg-blue-500 oprema:bg-opremayellow-500 text-white oprema:text-black">
            <div class="o-chrome-container h-full flex flex-col md:flex-row md:items-center justify-between text-12 md:text-13">
                <section v-if="companyDetails" class="company-info">
                    <span v-if="companyDetails.companyName" class="font-bold">{{ companyDetails.companyName }}</span>
                    <span v-if="companyDetails.addressInformation">{{ companyDetails.addressInformation }}</span>
                    <span v-if="companyDetails.postalCode || companyDetails.city">{{ companyDetails.postalCode }} {{ companyDetails.city }}</span>
                    <span v-if="companyDetails.vatNumber">{{ $dictionary.get('Footer.VatNo') }} {{ companyDetails.vatNumber }}</span>
                    <span v-if="$dictionary.exists('Footer.GroupLabel')">{{ $dictionary.get('Footer.GroupLabel') }}</span>
                    <span v-if="$dictionary.exists('Footer.GroupLabel2')">{{ $dictionary.get('Footer.GroupLabel2') }}</span>
                </section>
                <section v-if="showLanguageInformation" class="md:whitespace-nowrap" @click="activateSitePicker">
                    <span class="text-13 font-bold mr-15">{{ CountryAndLanguageLabel }}</span>
                    <call-to-action class="cta--small cta--white">
                        {{ $dictionary.get('Footer.ChangeCountryAndLanguage') }}
                    </call-to-action>
                </section>
            </div>
        </section>
        <script v-if="companyDetailsSchemaJson"
                type="application/ld+json"
                v-html="companyDetailsSchemaJson"/>
        <script v-if="breadcrumbsSchemaJson"
                type="application/ld+json"
                v-html="breadcrumbsSchemaJson"/>
    </footer>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { BreadcrumbItem, FooterCompanyDetails, FooterSitemapColumnViewModel } from '@/types/contentServerContract';
import authService from '@/core/auth/auth.service';
import siteService from '@/project/site/site.service';
import {
    CountryOptionsViewModel,
    EmployeeViewObject,
    SalesRepViewObject
} from '@/types/apiServerContract';
import Api from '@/project/http/Api.service';
import appStore from '@/core/app/app.store';
import { SHOW_SITE_PICKER } from '@/project/modals/overlays/SitePickerOverlay.service';
import bus from '@/core/bus';
import serverContextService from '@/core/serverContext.service';
const EmployeeBlockItem = () => import('@/project/app/blocks/information/EmployeeBlockItem.vue');
@Component({
    components: { EmployeeBlockItem }
})
export default class PageFooter extends Vue {
    salesRep: SalesRepViewObject | null = null;

    get companyDetails(): FooterCompanyDetails | null {
        return serverContextService.footer?.companyDetails || null;
    }

    get firstLinkColumns(): FooterSitemapColumnViewModel[] {
        return serverContextService?.footer?.sitemapColumns ?? [];
    }

    activateSitePicker() {
        bus.emit(SHOW_SITE_PICKER);
    }

    get isLoggedIn() {
        return authService.isLoggedIn();
    }

    @Watch('isLoggedIn', {
        immediate: true
    })
    onIsLoggedInChange(isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.getSalesRep();
        } else {
            this.salesRep = null;
        }
    }

    get countryCode(): string | null {
        return siteService.getCountryCode();
    }

    get siteOptions(): CountryOptionsViewModel | null {
        return siteService.getCountryOptions();
    }

    get showLanguageInformation(): boolean {
        if (this.siteOptions?.countries.length && (this.siteOptions.countries.length > 1 || this.siteOptions.countries[0].sites.length > 1)) {
            return true;
        }
        return false;
    }

    @Watch('countryCode')
    onCountryCodeChange() {
        if (this.isLoggedIn) {
            this.getSalesRep();
        } else {
            this.salesRep = null;
        }
    }

    get CountryAndLanguageLabel(): string {
        const lang = siteService.getLanguageCode() || '';
        const countryCode = siteService.getCountryCode() || '';
        return `${this.$dictionary.countryNameByCountryCode(countryCode)} / ${this.$dictionary.languageNameByLanguageCode(lang)}`;
    }

    async getSalesRep(): Promise<void> {
        const response = await Api.user.salesRep();
        const salesRepIsValid = this.checkIfSalesrepContainsData(response.salesRep);
        this.salesRep = salesRepIsValid ? response.salesRep! : null;
    }

    checkIfSalesrepContainsData(rep?: SalesRepViewObject) {
        return !!rep && Object.values(rep).some(x => !!x);
    }

    get salesRepAsEmployeeViewObject(): EmployeeViewObject | null {
        if (!this.salesRep) return null;
        return {
            photoUrl: this.salesRep.picture,
            mobilePhone: this.salesRep.mobilePhoneNo,
            directPhone: this.salesRep.directPhoneNo || '',
            displayName: this.salesRep.name || '',
            title: this.salesRep.title || '',
            email: this.salesRep.email || '',
            initials: '',
            id: ''
        };
    }

    get shouldRender(): boolean {
        return !appStore.isTunnelView;
    }

    get isOprema() {
        return serverContextService?.isOprema;
    }

    get companyDetailsSchemaJson() {
        const socialMediaLinks = this.firstLinkColumns.filter(c => c.socialMediaLinks?.length).map(some => some.socialMediaLinks.map(link => link.url));
        const schema: any = {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: `${document.location.origin}${window.servercontext.url}`,
            logo: `${window.servercontext.siteAssetsUrl}/favicon.svg`,
            foundingDate: this.isOprema ? '2010' : '1986',
            name: this.companyDetails?.companyName
        };
        if (this.companyDetails?.email) {
            schema.email = this.companyDetails?.email;
        }
        if (this.companyDetails?.phoneNumber) {
            schema.telephone = this.companyDetails?.phoneNumber;
        }
        if (this.companyDetails?.phoneNumber || this.companyDetails?.email) {
            schema.contactPoint = {
                '@type': 'ContactPoint',
                telephone: this.companyDetails?.phoneNumber,
                email: this.companyDetails?.email
            };
        }
        if (this.companyDetails?.city && this.companyDetails.addressInformation && this.companyDetails.postalCode) {
            schema.address = {
                '@type': 'PostalAddress',
                addressLocality: this.companyDetails?.city,
                streetAddress: this.companyDetails?.addressInformation,
                postalCode: this.companyDetails?.postalCode
            };
        }
        if (socialMediaLinks.length) {
            schema.sameAs = socialMediaLinks.flat(1) || [];
        }
        return JSON.stringify(schema);
    }

    get breadcrumbsSchemaJson() {
        const jsonldobj:any = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: []
        };
        // Parent pages
        appStore.breadcrumbPrettified.map((item: BreadcrumbItem, index) => {
            jsonldobj.itemListElement.push({
                '@type': 'ListItem',
                position: (index + 1),
                name: item.name,
                item: item.url ? `${document.location.origin}${window.servercontext.url}${item.url}` : window.location.href
            } as any);
        });
        return JSON.stringify(jsonldobj);
    }
}
</script>

<style lang="less" scoped>
    .address-n-site {
        height: 15.6rem;
    }

    .company-info {
        span {
            display: block;
            line-height: 20px;
        }
    }

    @screen md {
        .address-n-site {
            height: 8rem;
        }

        .company-info {
            @apply flex flex-wrap;

            span:not(:last-child) {
                &:after {
                    content: '•';
                    font-weight: 500;
                    margin: 0 1rem;
                }
            }
        }
    }

    .social-media-link {
        img {
            width: 18px;
            height: 18px;
        }
    }
</style>
